import React from "react";
import Container from "../Container";
import Row from "../Row";
import Col from "../Col";
import styled from "styled-components";
import { theme } from "../../utils/styled_config";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../Title";
import Paragraph from "../Paragraph";

export const AboutP = () => {
  return (
    <Container>
      <Row justify="center" mb={10} mbLg={20}>
        <CenterCol lg={12}>
          <Title size={3} lg={"d3"} slim="1">
            Our Team
          </Title>
          <Paragraph>The people behind the work</Paragraph>
        </CenterCol>
      </Row>

      <Row justify="center" mb={16} mbLg={0}>
        <Col lg={10}>
          <Row>
            <Col md={4} mb={8}>
              <Team>
                <TeamInfo>
                  <Danger>Nicole Bernabe</Danger>
                  <br />
                  Head of Operations
                  <br />
                  and Project Management
                </TeamInfo>
                <StaticImage
                  src="../../images/nicole-bernabe.jpg"
                  alt="Nicole Bernabe"
                  layout="fullWidth"
                />
              </Team>
            </Col>
            <Col md={4} mb={8}>
              <Team>
                <TeamInfo>
                  <Purple>Fatima Yambao</Purple>
                  <br />
                  Head of Design
                  <br />
                  and Innovation
                </TeamInfo>
                <StaticImage
                  src="../../images/fatima-yambao.jpg"
                  alt="Fatima Yambao"
                  layout="fullWidth"
                />
              </Team>
            </Col>

            <Col md={4} mb={8} mbMd={0}>
              <Team>
                <TeamInfo>
                  <Info>Jack Leow</Info>
                  <br />
                  Head of Sales and
                  <br />
                  Business Development
                </TeamInfo>
                <StaticImage
                  src="../../images/jack-loew.jpg"
                  alt="Jack Leow"
                  layout="fullWidth"
                />
              </Team>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const CenterCol = styled(Col)`
  text-align: center;
`;

const Team = styled.div`
  position: relative;
`;

const TeamInfo = styled.div`
  position: absolute;
  top: 30px;
  left: 27px;
  color: white;
  z-index: 1;
  font-size: 12px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  max-width: 140px;

  @media screen and (min-width: 1265px) {
    max-width: none;
  }

  @media screen and (max-width: 992px) {
    left: 15px;
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 420px) {
    max-width: 120px;
  }
`;

const Danger = styled.span`
  color: ${theme.color.danger};
`;

const Info = styled.span`
  color: ${theme.color.info};
`;

const Warning = styled.span`
  color: ${theme.color.warning};
`;

const Purple = styled.span`
  color: ${theme.color.purple};
`;
