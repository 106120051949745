import React from "react";
import Container from "../Container";
import Row from "../Row";
import Col from "../Col";
import styled from "styled-components";
import { theme } from "../../utils/styled_config";
import Section from "../Section";

export const AboutL = () => {
  return (
    <Section>
      <Container>
        <Row justify="center" mt={20}>
          <CenterCol lg={7} mb={15} mbLg={20}>
            <T1>
              Our goal is to have technology adapt to the needs of our clients,
              versus the other way around.
            </T1>
          </CenterCol>

          <Col lg={10}>
            <CenterCol>
              <T2>
                Our mission is to always <Violet>move forward</Violet> and{" "}
                <Warning>keep creating</Warning>!
              </T2>
            </CenterCol>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

const CenterCol = styled(Col)`
  text-align: center;
`;
const Warning = styled.span`
  color: ${theme.color.warning};
`;
const Violet = styled.span`
  color: ${theme.color.pink};
`;
const T1 = styled.div`
  position: relative;
  font-size: 24px;
`;
const T2 = styled.div`
  position: relative;
  font-size: 32px;
`;
