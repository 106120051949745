import React from "react";
import Container from "../Container";
import Row from "../Row";
import Col from "../Col";
import styled from "styled-components";
import { theme } from "../../utils/styled_config";
import Title from "../../components/Title";
import Paragraph from "../Paragraph";
import Section from "../Section";
import { StaticImage } from "gatsby-plugin-image";

export const AboutS = () => {
  return (
    <Section>
      <Container>
        <Row justify="center" mb={10} mbLg={20}>
          <CenterCol lg={12}>
            <Title size={3} lg={"d3"} slim="1">
              Our Services
            </Title>
            <Paragraph>Our process and capabilities</Paragraph>
          </CenterCol>
        </Row>

        <Row mb={15} justify="center">
          <Col col="auto" lg={4} mb={6} mbLg={0}>
            <StaticImage
              src={"../../images/services-1.png"}
              alt="girl typing"
              layout="constrained"
              width={288}
            />
          </Col>
          <Col lg={8}>
            <Title size={3} mb={4}>
              Design
            </Title>
            <Paragraph>
              Sleek design and rich content is just the icing on the cake when
              it comes to what our designers can do. We go much deeper and study
              the processes of how people interact with what we make, ensuring
              that their experience fits well with your current goals.
            </Paragraph>
            <Paragraph></Paragraph>
            <Row>
              <Col lg={6} xl={5}>
                <Detail>
                  <Danger as={Number}>01</Danger> Digital Branding
                </Detail>
                <Detail>
                  <Danger as={Number}>02</Danger> Logo and Vector Design
                </Detail>
                <Detail>
                  <Danger as={Number}>03</Danger> Responsive Design
                </Detail>
                <Paragraph />
              </Col>
              <Col lg={6} xl={5}>
                <Detail>
                  <Danger as={Number}>04</Danger> Video and Photography
                </Detail>
                <Detail>
                  <Danger as={Number}>05</Danger> UI/UX Design
                </Detail>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row mb={15} justify="center">
          <Col col="auto" lg={4} mb={6} mbLg={0}>
            <StaticImage
              src={"../../images/services-2.png"}
              alt="girl typing"
              layout="constrained"
              width={288}
            />
          </Col>
          <Col lg={8}>
            <Title size={3} mb={4}>
              Development
            </Title>
            <Paragraph>
              Is your website obsolete? Need a mobile app for the smartphone?
              Does your business need a boost in efficiency through the
              automation of some business processes? With our team of highly
              trained in-house developers, we can provide whatever your company
              needs in terms of development. We take a look at your
              requirements, provide the best and most effective solution, then
              we implement in a timely manner.
            </Paragraph>
            <Paragraph />
            <Row>
              <Col lg={6} xl={5}>
                <Detail>
                  <Danger as={Number}>01</Danger> Website Development and Design
                </Detail>
                <Detail>
                  <Danger as={Number}>02</Danger> E-Commmerce
                </Detail>
                <Detail>
                  <Danger as={Number}>03</Danger> Mobile Applications (iOS or
                  Android)
                </Detail>
                <Paragraph />
              </Col>
              <Col lg={6} xl={5}>
                <Detail>
                  <Danger as={Number}>04</Danger> Enterprise Software
                </Detail>
                <Detail>
                  <Danger as={Number}>05</Danger> Internal Systems and Software
                </Detail>
                <Detail>
                  <Danger as={Number}>06</Danger> Chatbot
                </Detail>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col col="auto" lg={4} mb={6} mbLg={0}>
            <StaticImage
              src={"../../images/services-3.png"}
              alt="girl typing"
              layout="constrained"
              width={288}
            />
          </Col>
          <Col lg={8}>
            <Title size={3} mb={4}>
              Technology Consultation and Outsourcing
            </Title>
            <Paragraph>
              Need a team to assess and manage the over-all technological
              concerns of your company? With more than a decade of experience in
              the I.T. industry, our team can provide the support, research,
              plan and execution for any requirement. A different timezone is
              also no problem, as we have the processes in place to take
              international clients.
            </Paragraph>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

const CenterCol = styled(Col)`
  text-align: center;
`;

const Danger = styled.span`
  color: ${theme.color.danger};
`;

const Number = styled.span`
  font-size: 10px;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 5px;
`;

const Detail = styled(Paragraph)`
  position: relative;
  padding-left: 18px;
`;
