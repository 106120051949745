import styled, { css } from "styled-components";
import { theme, BREAKPOINTS } from "../../utils/styled_config";

const AboutF = styled.div`
  font-family: "Prompt", sans-serif;
  line-height: ${(p) => (p.slim ? "1.2" : "1.5")};
  letter-spacing: ${(p) => (p.slim ? "-2px" : "inheirt")};

  ${(p) =>
    p.size &&
    css`
      font-size: ${handleTitleSize(p.size)};
    `}

  ${(p) =>
    p.mb &&
    css`
      margin-bottom: ${`${p.mb === "auto" ? "auto" : p.mb * theme.spacer}px`};
    `}

  @media screen and (min-width: ${BREAKPOINTS.xs}) {
    ${(p) =>
      p.xs &&
      css`
        font-size: ${handleTitleSize(p.xs)};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}) {
    ${(p) =>
      p.sm &&
      css`
        font-size: ${handleTitleSize(p.sm)};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.md}) {
    ${(p) =>
      p.md &&
      css`
        font-size: ${handleTitleSize(p.md)};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    ${(p) =>
      p.lg &&
      css`
        font-size: ${handleTitleSize(p.lg)};
      `}

    ${(p) =>
      p.mbLg &&
      css`
        margin-bottom: ${`${
          p.mbLg === "auto" ? "auto" : p.mbLg * theme.spacer
        }px`};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.xl}) {
    ${(p) =>
      p.xl &&
      css`
        font-size: ${handleTitleSize(p.xl)};
      `}
  }
`;

const handleTitleSize = (size) => {
  switch (size) {
    case "d1":
      return `${theme.heading.d1}`;
    case "d2":
      return `${theme.heading.d2}`;
    case "d3":
      return `${theme.heading.d3}`;
    case "d4":
      return `${theme.heading.d4}`;
    case 1:
      return `${theme.heading.h1}`;
    case 2:
      return `${theme.heading.h2}`;
    case 3:
      return `${theme.heading.h3}`;
    case 4:
      return `${theme.heading.h4}`;
    case 5:
      return `${theme.heading.h5}`;
    case 6:
      return `${theme.heading.h6}`;
    default:
      return `${theme.heading.h1}`;
  }
};

export default AboutF;
