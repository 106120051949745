import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Container from "../components/Container";
import Row from "../components/Row";
import Col from "../components/Col";
import styled from "styled-components";
import Section from "../components/Section";
import { AboutA } from "../components/About/AboutA";
import { AboutS } from "../components/About/AboutS";
import { AboutP } from "../components/About/AboutP";
import { AboutL } from "../components/About/AboutL";
import AboutF from "../components/About/AboutF";
import { theme, BREAKPOINTS } from "../utils/styled_config";
// markup
const AboutUs = () => {
  return (
    <Layout>
      <Seo title="About Us" />
      <Section>
        <Container>
          <Row justify="center">
            <ColCenterMobile lg={10}>
              <AboutF size={3} lg={"d3"} slim="1">
                Our process is <Danger>simple.</Danger>
                <Break /> We <Info>strategize</Info>,{" "}
                <Warning>implement</Warning>, then <Danger>iterate</Danger>,
                <Break /> creating a value loop that ensures our clients the
                best product possible.
              </AboutF>
            </ColCenterMobile>
          </Row>
        </Container>
      </Section>
      <AboutA />
      <AboutS />
      <AboutP />
      <AboutL />
    </Layout>
  );
};

const ColCenterMobile = styled(Col)`
  @media screen and (max-width: ${BREAKPOINTS.lg}) {
    text-align: center;
  }
`;

const Danger = styled.span`
  color: ${theme.color.danger};
`;

const Info = styled.span`
  color: ${theme.color.info};
`;

const Warning = styled.span`
  color: ${theme.color.warning};
`;

const Break = styled.span`
  display: none;

  @media screen and (min-width: 1140px) {
    display: block;
  }
`;

export default AboutUs;
