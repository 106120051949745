import React from "react";
import Container from "../Container";
import Row from "../Row";
import Col from "../Col";
import styled from "styled-components";
import Section from "../Section";
import { theme } from "../../utils/styled_config";

import { StaticImage } from "gatsby-plugin-image";
import Paragraph from "../Paragraph";
import Title from "../Title";

export const AboutA = () => {
  return (
    <>
      <Section>
        <Container>
          <Row justify="center">
            <Col lg={10}>
              <Row align="center" justify="center">
                <Col col={4} lg={5} mb={6} mbLg={0} orderLg="last">
                  <StaticImage
                    src={"../../images/t-whole.png"}
                    alt="girl typing"
                    quality={100}
                    layout="constrained"
                    width={375}
                  />
                  <br />
                </Col>
                <Col lg={7} mb={4} mbLg={0}>
                  <Paragraph>
                    We are a proven digital solutions provider that's composed
                    of young and passionate people that are ready to take any
                    challenge thrown our way. We are hungry, foolish, and out to
                    conquer the world.
                  </Paragraph>
                </Col>
              </Row>
              <Row>
                <Col col="auto" hiddenMobile={true}>
                  <StaticImage
                    src="../../images/scroll.png"
                    height={167}
                    alt="scroll"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row align="center" justify="center" mb={10} mbLg={0}>
            <Col col={10} xs={8} lg={6} mb={6} mbLg={0} orderLg="last">
              <StaticImage
                src={"../../images/about-1.png"}
                alt="meeting room"
                layout="fullWidth"
              />
              <br />
            </Col>
            <Col lg={6} orderLg="last" mb={4} mbLg={0}>
              <Paragraph>
                We aim to foster a happy and nurturing atmosphere for our people
                where the emphasis is on personal growth, family-type
                relationship and the often forgotten aspect of most jobs—the
                feeling of accomplishment. We encourage the sense of fun, but at
                the same time understand that in this line of work, timely
                results is what ultimately matters.
              </Paragraph>
            </Col>
          </Row>

          <Row align="center" justify="center">
            <Col lg={6} mb={4} mbLg={0}>
              <Title size={2} lg="d4">
                "It's not only about ideas, it's about making{" "}
                <Warning> ideas happen.</Warning>"
              </Title>
            </Col>
            <Col col={10} xs={8} lg={6} mb={6} mbLg={0} hiddenMobile="true">
              <StaticImage
                src={"../../images/about-2.png"}
                alt="girl typing"
                layout="fullWidth"
              />
              <br />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

const Warning = styled.span`
  color: ${theme.color.warning};
`;
